
import { GetToken } from "@/services/auth.service";
import { Options, setup, Vue } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    data() {
        return {
            loginFormData: {
                username: "",
                password: "",
            },
        };
    },
    components: {},
    methods: {
        onSubmit() {
            GetToken(this.loginFormData)
                .then(() => {
                    this.$router.push("/");
                })
                .catch((err) => {
                    this.toast.error(
                        "Failed to login, please check your credentials and try again."
                    );
                });
        },
    },
})
export default class LogIn extends Vue {
    toast = setup(() => useToast());
}
